import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, output, PLATFORM_ID } from '@angular/core';
import { distinctUntilKeyChanged, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrl: './main-toolbar.component.scss',
  host: {
    ngSkipHydration: 'true'
  }
})
export class MainToolbarComponent implements OnInit, OnDestroy {

  public openMenu = output<boolean>();

  public signInURL: string | undefined;

  public signUpURL: string | undefined;

  private unsub: Subject<void> = new Subject();

  public isHandheld: boolean = false;

  public constructor(
    private breakpointsObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {}

  public ngOnInit(): void {
    if(isPlatformBrowser(this.platformID)) {
      this.subscribeToBreakPoints();
    }
  }

  public ngOnDestroy(): void {
      
  }


  /**
   * Listens to the layout breakpoints observer and
   * sets the {isHandheld} property.
   */
  public subscribeToBreakPoints(): void {
    this.breakpointsObserver
      .observe([Breakpoints.Handset])
      .pipe(
        takeUntil(this.unsub),
        distinctUntilKeyChanged('matches'),
        map(state => this.isHandheld = state.matches)
      ).subscribe();
  }

}
